// * LAYOUT MODULES
import { useReactiveVar } from '@apollo/client'
import { Backdrop, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { Newsletter, Section } from '../components/DesktopElements/Utils'
import { Footer, Header } from '../components/Modules'
import { dropdownStateVar } from '../store/reactiveVars'

// --- We remove dynamic from footer, because we want first links to load (to category pages)
// const Footer = dynamic(() => import("../components/Modules/Footer"), {
//   ssr: false,
// });

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      backdrop: {
        zIndex: 2,
        color: '#fff',
      },
      section: {
        marginTop: theme.spacing(10),
        [theme.breakpoints.down('lg')]: {
          marginTop: theme.spacing(5),
        },
      },
      main: {
        [theme.breakpoints.up('lg')]: {
          marginTop: '11rem',
        },
      },
    }),
  { name: 'MuiLayoutComponent' },
)

function Layout(props) {
  const { locale, categoriesData } = props
  const classes = useStyles() as any
  const dropdownState = useReactiveVar(dropdownStateVar)

  return (
    <div>
      <Backdrop open={dropdownState} className={classes.backdrop} />
      <Header data={categoriesData} locale={locale} />

      <main className={classes.main}>{props.children}</main>
      <Section fullWidth disablePadding className={classes.section}>
        <Newsletter />
      </Section>
      <Footer data={categoriesData} locale={locale} />
    </div>
  )
}

export default Layout
