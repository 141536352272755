import { useEffect } from 'react'
import { shopSettingsVar } from '../store/reactiveVars'

export const useShopSettingsInit = (shopSettings) => {
  useEffect(() => {
    if (shopSettings) {
      shopSettingsVar({ ...shopSettings })
    }

    return
  }, [])
}
