import { createContext, useContext } from "react";

const HomeContext = createContext(null);

export function HomeContextWrapper({ data, locale, shopSettings, children }) {
  return (
    <HomeContext.Provider value={{ data, locale, shopSettings }}>
      {children}
    </HomeContext.Provider>
  );
}

export function useHomeContext() {
  return useContext(HomeContext);
}
