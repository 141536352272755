//-----
export const getCanonicalUrl = (locale: string, enabledLanguages) => {
  let languageOfLocale = null
  let langaugesWithWebAddreses = [] as any

  let canonicalURLToReturn = ''

  if (enabledLanguages && enabledLanguages.length) {
    for (const enabledLang of enabledLanguages) {
      if (enabledLang?.web_address_enabled) {
        langaugesWithWebAddreses.push(enabledLang)
      }
      if (enabledLang.language === locale) {
        // We have a match
        languageOfLocale = enabledLang
      }
    }
  }

  if (languageOfLocale?.web_address_enabled) {
    //-- Return the web address of enabled lang
    canonicalURLToReturn = languageOfLocale.web_address
  } else {
    //--- Choose the first addres from languages withWebAddresses
    if (langaugesWithWebAddreses[0]) {
      canonicalURLToReturn = langaugesWithWebAddreses[0].web_address
    }
  }

  if (canonicalURLToReturn.endsWith('/')) {
    canonicalURLToReturn = canonicalURLToReturn.slice(0, -1)
  }

  return canonicalURLToReturn
}

// export const getCanonicalUrlOrriginal = (locale: string) => {
//   switch (locale) {
//     case 'lt':
//       return `https://www.furnibay.lt`
//     case 'lv':
//       return `https://www.furnibay.lv`
//     case 'et':
//       return `https://www.furnibay.ee`
//     case 'en':
//       return `https://www.furnibay.com`
//     case 'ru':
//       return 'https://www.furnibay.com'

//     default:
//       console.log(
//         "getCanonicalUrl didn't find any match, you probably forgot to add the language to this switch!",
//       )

//       return 'https://www.furnibay.com`'
//       break
//   }
// }
